import { render, staticRenderFns } from "./knowledgeManagement.vue?vue&type=template&id=64a5020c&scoped=true"
import script from "./knowledgeManagement.vue?vue&type=script&lang=js"
export * from "./knowledgeManagement.vue?vue&type=script&lang=js"
import style0 from "./knowledgeManagement.vue?vue&type=style&index=0&id=64a5020c&prod&lang=less"
import style1 from "./knowledgeManagement.vue?vue&type=style&index=1&id=64a5020c&prod&scoped=true&lang=less"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "64a5020c",
  null
  
)

export default component.exports